/*
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2023-10-08 08:59:44
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2023-10-14 11:20:35
 * @FilePath: \1008\zonghoutai-front\src\axios\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 封装的axios
 */
import axios from 'axios'


// import qs from 'querystring'

/**
 * 错误函数 解析常用的http状态码
 * 2xx：成功
 * 3xx: 重定向
 * 4xx: 资源不存在
 * 5xx：服务器错误
 *
 */
const  ErrorHandle=(status, info) => {
    switch (status) {
        case 400:
            console.log('400-表示请求报文中存在语法错');
            break;
        case 401:
            console.log('401-未经许可，需要通过HTTP认证');
            break;
        case 403:
            console.log('403-服务器拒绝该次访问（访问权限出现问题）');
            break;
        case 404:
            console.log('404-表示服务器上无法找到请求的资源');
            break;
        case 500:
            console.log('500-表示服务器在执行请求时发生了错误，也有可能是web应用存在的bug或某些临时的错误时；');
            break;
        case 503:
            console.log('503-表示服务器暂时处于超负载或正在进行停机维护，无法处理请求');
            break;
        default:
            console.log(info);
            break;
    }
}

//自定义新建一个 axios 实例
const install = axios.create({
    timeout: 5000,//请求等待时间 时间到了后台没有返回数据 请求中段
})


//拦截器
// 添加请求拦截器
install.interceptors.request.use(function (config) {
    //处理post请求发送的参数格式
    if(config.method==='post'){
        // config.data = qs.stringify(config.data)//{} xx=xx&xx=xx
    }
    let token = localStorage.getItem('mytoken')|| '';
         if (token) {
        //加入到请求头
        config.headers.Authorization = 'Bearer ' + token
        // config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
    }


    // console.log('添加请求拦截器--config',config);
    // 在发送请求之前做些什么
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
install.interceptors.response.use(
    response =>{
      return response.data

    },
    error=>{
        console.log('响应拦截器错误',error);
        let { response } = error;
        // 对响应错误做点什么
        ErrorHandle(response.status,response.info)
  });



export function get(url,params){
    return install.get(url,{
        params
    }).then(res=>res.data)
}

export default  install;

// import axios from 'axios';
// // 假设你的加密解密函数在这里
// import { encrypt, decrypt } from './encrypt';
//
// const ErrorHandle = (status, info) => {
//     // 错误处理逻辑...
// };
//
// const install = axios.create({
//     timeout: 5000, // 请求等待时间
// });
//
// // 添加请求拦截器
// install.interceptors.request.use(function (config) {
//     let token = localStorage.getItem('mytoken') || '';
//     if (token) {
//         config.headers.Authorization = 'Bearer ' + token;
//     }
//
//     // 对所有请求的数据进行加密，并将加密数据作为请求体发送
//     if (config.data && !(config.data instanceof FormData)) {
//         // 将整个请求体数据加密
//         const encryptedData = encrypt(JSON.stringify(config.data));
//         // 使用加密后的数据作为请求体
//         config.data = encryptedData;
//         // 设置请求头，告知服务器请求体是加密数据
//         config.headers['Content-Type'] = 'text/plain';
//     }
//
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });
//
// // 添加响应拦截器
// install.interceptors.response.use(response => {
//     console.log('响应类型:', typeof response.data);
//     console.log('响应内容:', response.data);
//     // 假设服务器返回的也是加密数据，这里进行解密
//     if (response.data && typeof response.data === 'string') {
//         try {
//             const decryptedData = decrypt(response.data.data); // 解密响应数据
//             console.log(decryptedData);
//             response.data = JSON.parse(decryptedData); // 将解密后的字符串转换为JSON对象
//         } catch (error) {
//             console.error('解密或解析错误', error);
//         }
//     }
//     return response;
// }, error => {
//     console.log('响应拦截器错误', error);
//     let { response } = error;
//     ErrorHandle(response.status, response.info);
//     return Promise.reject(error);
// });
//
// export default install;
