<template>
  <div >
    <div id="head">
      <div id="head_text">Meal Selection</div>
<!--      <img-->
<!--        src="@/assets/image/menuIcon.png"-->
<!--        alt=""-->
<!--        @click="drawer = !drawer"-->
<!--        class="model"-->
<!--      />-->
      <div class="menuView" @click="drawer = !drawer">
        MENU
      </div>
    </div>
    <div class="model_menu">
      <el-collapse-transition>
        <div v-show="drawer">
          <Modelmenu @collapse="collapse" />
        </div>
      </el-collapse-transition>
    </div>
    <Main></Main>
  </div>
</template>
<script>
import Main from "./main";
import Modelmenu from "../components/ModelMenu.vue";
import "@/elementStyle/fonts/style.css";

export default {
  components: {
    Main,
    Modelmenu,
  },
  data() {
    return {
      drawer: false,
    };
  },
  created(){
    console.log(window.location,'111')
  },
  methods: {
    collapse() {
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
 #head {

    z-index: 99;
    position: fixed;
    top: 0;
    background: #78964a;
    color: #ffffff;
    width: 100%;
    height: 3%;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
       display: none;
  }
  .menuView {
    display: none;
  }

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media screen and (max-width: 768px) {
  #app {
    #head {
      display: flex;
      box-sizing: border-box;
      height: 48px;
      padding: 0 5%;
      justify-content: space-between;
      img {
        width: 25px;
      }
    }
    .model {
      display: block;
    }
    .model_menu {
      width: 100%;
      background: #fff;
      position: fixed;
      top: 48px;
      z-index: 888;
      // margin-top: 48px;
    }
    .menuView{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 5px solid #FFFFFF;
    }
  }
}
</style>

