import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '../views/Layout.vue'


Vue.use(VueRouter)


const routes = [
    {
        path: '',
        name: 'Layout',
        component: Layout,
        children:[
            {
                path: '/search',
                name: '/search',
                // icon: "el-icon-s-claim",
                // component: () => import('../views/MyPreference/MyPreference.vue'),
                component: () => import('../views/Search.vue'),
                meta: {
                    title: 'search'
                },
            },
            {
                path: '/home/:id',
                name: '/HomePage',
                // icon: "el-icon-s-claim",
                // component: () => import('../views/MyPreference/MyPreference.vue'),
                component: () => import('../views/HomePage/HomePage.vue'),
                meta: {
                    title: 'HomePage'
                },
            },
            {
                path: '/Register',
                name: '/Register',
                // icon: "el-icon-s-claim",
                component: () => import('../views/Register/Register.vue'),
                meta: {
                    title: 'Register'
                },
            },
            {
                path: '/testMap',
                name: '/testMap',
                // icon: "el-icon-s-claim",
                component: () => import('../views/testMap/testMap.vue'),
                meta: {
                    title: 'testMap'
                },
            },
            {
                path: '/Help',
                name: '/Help',
                // icon: "el-icon-s-claim",
                component: () => import('../views/Help/Help.vue'),
                meta: {
                    title: 'Help'
                },
            },
            {
                path: '/Forget',
                name: '/Forget',
                // icon: "el-icon-s-claim",
                component: () => import('../views/Forget/Forget.vue'),
                meta: {
                    title: 'Forget'
                },
            },
            {
                path: '/MyBenefit',
                name: '/MyBenefit',
                // icon: "el-icon-s-claim",
                component: () => import('../views/MyBenefit/MyBenefit.vue'),
                meta: {
                    title: 'MyBenefit'
                },
            },
            {
                path: '/ModifyMyMeals',
                name: '/ModifyMyMeals',
                // icon: "el-icon-s-claim",
                component: () => import('../views/ModifyMyMeals/ModifyMyMeals.vue'),
                meta: {
                    title: 'ModifyMyMeals'
                },
            },
            {
                path: '/signIn',
                name: '/signIn',
                // icon: "el-icon-s-claim",
                component: () => import('../views/signIn/signIn.vue'),
                meta: {
                    title: 'signIn'
                },
            },
            {
                path: '/ForgetIndex',
                name: '/ForgetIndex',
                // icon: "el-icon-s-claim",
                component: () => import('../views/Forget/index.vue'),
                meta: {
                    title: 'ForgetIndex'
                },
            },
            {
                path: '/ForgetUserName',
                name: '/ForgetUserName',
                // icon: "el-icon-s-claim",
                component: () => import('../views/Forget/ForgetUserName.vue'),
                meta: {
                    title: 'ForgetUserName'
                },
            },
            // {
            //     path: '/MyPreference',
            //     name: '/MyPreference',
            //     // icon: "el-icon-s-claim",
            //     component: () => import('../views/MyPreference/MyPreference.vue'),
            //     meta: {
            //         title: 'MyPreference'
            //     },
            // },
            {
                path: '/RateMyMeals',
                name: '/RateMyMeals',
                // icon: "el-icon-s-claim",
                component: () => import('../views/RateMyMeals/RateMyMeals.vue'),
                meta: {
                    title: 'RateMyMeals'
                },
            },
            {
                path: '/Survey',
                name: '/Survey',
                // icon: "el-icon-s-claim",
                component: () => import('../views/Survey/Survey.vue'),
                meta: {
                    title: 'Survey'
                },
            },

            {
                path: '/foodDetails',
                name: 'foodDetails',
                // icon: "el-icon-s-claim",
                component: () => import('../views/foodDetails/index.vue'),
                meta: {
                    title: 'Food Details'
                },
            },
        ]

    },
    {
        path:"/error",
        component: () => import('../views/Error.vue'),
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
